import { steps } from 'components/strombestilling/helpers/useTracking';
import { navigate } from 'gatsby';

export const stromPagesBaseUrl = '/strom/strombestilling';

export const stromPages = {
	login: { url: '/', step: steps[1], isFirstPage: true },
	salesCodeUsed: {
		url: '/salgskode-brukt',
		step: steps[11],
		isFirstPage: true,
	},
	loginSuccess: { url: '/laster' },
	addOrgNumber: { url: '/orgnr', step: steps[21] },
	pickPath: { url: '/velg', step: steps[2] },
	errorPage: { url: '/feil', step: steps[8] },
	searchAddress: { url: '/adresse', step: steps[31] },
	searchMeternumber: {
		url: '/maalernummer',
		step: steps[32],
	},
	pickMeter: { url: '/velg-maaler', step: steps[33] },
	addStartdate: { url: '/oppstartsdato', step: steps[4] },
	addContactinfo: {
		url: '/kontakt',
		step: steps[5],
	},
	confirmOrder: { url: '/oppsummering', step: steps[6] },
	orderSuccess: {
		url: '/takk-for-bestilling',
		step: steps[7],
		isLastpage: true,
	},
	orderFailed: {
		url: '/bestilling-feilet',
		step: steps[9],
		isLastpage: true,
		hideAppContent: true,
	},
};

export const stromUrlParams = {
	login: 'innlogging',

	// Underway errors (triggers error page)
	ruleBroken: 'regelbrudd',

	// Final successes
	manual: 'manuell',

	// Final errors (ends the order)
	tariffError: 'ugyldig-tariff',
	vervekodeError: 'ugyldig-vervekode',
	salgskodeError: 'ugyldig-salgskode',
	loginSsnError: 'mangler-personnummer',
	loginFetchError: 'logg-inn-feil',
	creditBlockedError: 'kredittsperre',
	creditFailedError: 'avslaatt-kredittvurdering',
	duplicateOrderError: 'duplikat-ordre',
	techError: 'teknisk-feil',
	unknownError: 'ukjent-feil',

	// Can't order manually when has elektro product order in session storage
	manualElektroError: 'manuell-elektro',
};

export default function usePageNavigation() {
	// Documentation: https://reach.tech/router/api/navigate

	const navigatePage = (nextPageUrl, queryParams) => {
		let url = stromPagesBaseUrl + nextPageUrl;
		if (queryParams) {
			url += '?' + queryParams;
		}

		navigate(url);
		window.scrollTo(0, 0);
	};

	const goBack = () => {
		navigate(-1);
		window.scrollTo(0, 0);
	};

	const getPageFromUrl = url => {
		let page;
		for (const [key, value] of Object.entries(stromPages)) {
			if (
				url === stromPagesBaseUrl + value.url + '/' ||
				url === stromPagesBaseUrl + value.url
			) {
				page = stromPages[key];
			}
		}
		return page || stromPages.login;
	};

	const getLogoutUrl = (url, queryParams, queryParamsValue) => {
		let logoutUrl = '';
		if (url) {
			logoutUrl = window?.location?.origin + stromPagesBaseUrl + url;
			if (queryParams) {
				logoutUrl += '?' + queryParams;
				if (queryParamsValue) {
					logoutUrl += '=' + queryParamsValue;
				}
			}
		} else {
			logoutUrl = window?.location?.href;
		}
		return logoutUrl;
	};

	return {
		navigatePage,
		goBack,
		getPageFromUrl,
		getLogoutUrl,
	};
}
