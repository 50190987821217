import { AnchorLink } from 'components/Link';
import AuthButtons from 'components/authentication/AuthButtons';
import AuthInformation from 'components/authentication/AuthInformation';
import {
	ContentWrapper,
	Layout,
	SubHeader,
} from 'components/strombestilling/components/StromStyles';
import { StromContext } from 'components/strombestilling/helpers/strombestillingProvider';
import {
	stromPages,
	stromPagesBaseUrl,
} from 'components/strombestilling/helpers/usePageNavigation';
import useSale, {
	salgsStatuser,
} from 'components/strombestilling/helpers/useSale';
import { useAuth } from 'context/AuthProvider';
import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';

const Info = styled.p`
	margin-bottom: 0;
`;

export function SpotprisInfo() {
	return (
		<>
			{' '}
			<SubHeader>Mer informasjon om Spotpris</SubHeader>
			<Info>
				Grunnprisen per kWh bestemmes av spotprisen fra NordPool
				Day-Ahead og er avhengig av hvilket geografisk område som
				gjelder for måleren. Du betaler spotprisen med påslag og et
				månedlig fastbeløp. Avtalen har 6 måneders bindingstid og
				prisene inkluderer lovpålagte elsertifikater. Alle strømkunder i
				Norge betaler for{' '}
				<AnchorLink
					href="/kundeservice/faktura/#elsertifikat"
					target="_blank"
					rel="noreferrer"
					title="Informasjon om elsertifikater">
					elsertifikater
				</AnchorLink>
				. Det er en lovpålagt kostnad som støtter produksjonen av
				fornybare energikilder som vannkraft, solenergi og vindkraft.
			</Info>
		</>
	);
}

export default function LoginStrombestilling() {
	const { isAuthenticated, isLoading, logout } = useAuth();
	const { order, storeBeforeLogout, clearElektroOrder } =
		useContext(StromContext);
	const { updateSaleStatus } = useSale();

	useEffect(() => {
		updateSaleStatus(salgsStatuser.opened);

		// Clear stored elektro order if tariff is not "elektro-spotpris"
		if (order?.tariffId !== 'elektro-spotpris') {
			clearElektroOrder();
		}

		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (isAuthenticated && !isLoading) {
			// If has stored product order (Elektro) in session storage then don't log out, proceed to step 1
			if (order?.isElektroOrder) {
				window.location.href =
					window.location.origin +
					stromPagesBaseUrl +
					stromPages.loginSuccess.url;
			} else {
				// Otherwise log out
				storeBeforeLogout();
				logout();
			}
		}
	}, [
		isAuthenticated,
		isLoading,
		logout,
		storeBeforeLogout,
		order?.isElektroOrder,
	]);

	return (
		<Layout>
			<ContentWrapper>
				<AuthButtons
					headinglevel="h1"
					headinglevelclass="h2"
					redirect={
						window.location.origin +
						stromPagesBaseUrl +
						stromPages.loginSuccess.url
					}
				/>
				<AuthInformation
					id="new-power-product-order-popup"
					size="small"
				/>
			</ContentWrapper>
		</Layout>
	);
}
